
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ComponentItemFormMixin from "./item-form.mixin";

@Component({
  components: {},
  mixins: [],
})
export default class StringFormBuilder extends Mixins(ComponentItemFormMixin) {
  render() {
    const { conf, value } = this;
    return (
      <q-input
        dense
        filled
        value={value || ""}
        label={`请输入${conf.label}`}
        onInput={(val: any) => this.$emit("change", val)}
        type={conf.stringType === "textarea" ? "textarea" : "text"}
      />
    );
  }
}
